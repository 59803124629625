import { useAuth } from "../../utils/AuthContext";

import LenderDashboard from "../Lenders/LenderDashboard/LenderDashboard";
import BorrowersDashboard from "../Borrowers/BorrowersDashboard";

const Dashboard = () => {
  const { user } = useAuth();

  return (
    <>
      {user?.role_details?.role_name === "Lender" ? (
        <LenderDashboard />
      ) : (
        <BorrowersDashboard />
      )}
    </>
  );
};

export default Dashboard;
