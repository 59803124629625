import { useEffect } from 'react';

const styleAuthConfig = {
  css_url: '/app/embeddedcss.css',
  is_customize_forgot_password: true,
  forgot_password_id: 'login',
  forgot_password_css_url: '/app/fpwd.css',
};

const Login = () => {
  useEffect(() => {
    window.catalyst.auth.signIn('login', styleAuthConfig);
  }, []);

  return <div id="login" style={{ height: '600px' }} />;
};
export default Login;
