import { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'rsuite';
import Loading from './../../../shared/Loading/Loading';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import moment from 'moment';
import '../BorrowersDashboard.scss';

const BeforeSignup = (props) => {
  const { lenders, latestDeal, dealID } = props;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showApplySuccessFailModal, setShowApplySuccessFailModal] =
    useState(false);
  const [lenderList, setLendersList] = useState([]);
  const [selectedLender, setSelectedLender] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [appliedSuccess, setAppliedSuccess] = useState(false);

  useEffect(() => {
    if (lenders) {
      let _lenders = lenders.filter((lender) => {
        if (!lender.Borrowers_Count || !lender.Borrowers_Limit) {
          return true;
        }
        if (lender.Borrowers_Count <= lender.Borrowers_Limit) {
          return true;
        }
        return false;
      });

      _lenders = sortByBlueText(_lenders);

      setLendersList(_lenders);
    }
  }, [lenders]);

  const sortByBlueText = (_lenders) => {
    _lenders.sort((a, b) => {
      if (a.Show_blue_text === null) {
        return 1;
      }

      if (b.Show_blue_text === null) {
        return -1;
      }
      if (a.Show_blue_text < b.Show_blue_text) {
        return 1;
      }
      if (a.Show_blue_text > b.Show_blue_text) {
        return -1;
      }
      return 0;
    });
    return _lenders;
  };

  const applyToLender = async (lenderID) => {
    setLoading(true);
    setShowError(false);
    setMessage('');
    setAppliedSuccess(false);
    try {
      const { data } = await axios.post(
        '/assignLender',
        {
          dealID,
          lenderID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setLoading(false);
      setMessage(data.message);
      if (data.status === 'fail') {
        setShowError(true);
        setSelectedLender({});
      } else {
        setAppliedSuccess(true);
      }
    } catch (err) {
      setLoading(false);
      setSelectedLender({});
      setMessage('Something went wrong!');
      setShowError(true);
    }
  };

  const calculateAppliedDays = (createdTime) => {
    let start = moment(createdTime);
    let end = moment();
    let weekdayCounter = 0;

    while (start <= end) {
      if (start.format('ddd') !== 'Sat' && start.format('ddd') !== 'Sun') {
        weekdayCounter++;
      }
      start = moment(start, 'YYYY-MM-DD').add(1, 'days');
    }
    return weekdayCounter;
  };

  const closeApplySuccessFailModal = () => {
    if (!showError) {
      setLendersList([]);
    }
    setShowApplySuccessFailModal(false);
  };

  const NGNCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'NGN',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const renderMessage = () => {
    if (showError) {
      return (
        <h3 className="message error">
          <InfoOutlineIcon />
          {message}
        </h3>
      );
    }
    return (
      <>
        <h3 className="message">
          You have now applied for a loan from {selectedLender.Vendor_Name}.
        </h3>
        <h3 className="message">
          Please allow a maximum of 2 business days for the lender to contact
          you and finalise your application.
        </h3>
      </>
    );
  };

  if (
    latestDeal.Lenders &&
    latestDeal.Created_Time &&
    calculateAppliedDays(latestDeal.Created_Time) <= 1
  ) {
    return (
      <div className="page-container">
        <div className="borrower-dashboard-page">
          <div className="page-center-msg">
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              You already applied for a loan on{' '}
              {latestDeal?.Created_Time.split('T')[0]}.
              <br />
              <span style={{ fontSize: '11px' }}>
                Please allow a maximum of 2 business days for the lender to
                contact you and finalise your application.
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!lenderList.length && appliedSuccess) {
    return <div className="page-center-msg">{renderMessage()}</div>;
  }

  return (
    <div className="page-container">
      <div className="borrower-dashboard-page">
        {lenderList.length > 0 ? (
          <>
            <div className="recommend-wrapper">
              {lenderList.map((lender) => (
                <div
                  className={`recommend-box ${
                    lender.Show_blue_text ? 'highlight' : ''
                  }`}
                  key={lender.id}
                >
                  {lender.Show_blue_text && (
                    <div className="highlight-title">
                      {lender.Show_blue_text}
                    </div>
                  )}
                  <div className="recommed-overview">
                    <div className="overview logo">
                      <img
                        width="180"
                        src={lender.Lender_Public_Logo_URL}
                        alt="Logo"
                      />
                      {/* <div className="title">{lender.Vendor_Name}</div> */}
                    </div>

                    <div className="overview-info">
                      <div className="overview-info-wrapper">
                        <div className="overview interest-rate">
                          <h2 className="value">
                            {lender.Maximum_Monthly_Interest_Rate}% / mo.
                          </h2>
                          <div className="title">Interest rate</div>
                        </div>
                        <div className="separator"></div>

                        <div className="overview org-fees">
                          <h2 className="value">{lender.Origination_fee}</h2>
                          <div className="title">Origination fee</div>
                        </div>
                        <div className="separator"></div>

                        <div className="overview serivce-fees">
                          <h2 className="value">{lender.Mo_Service_Fee}</h2>
                          <div className="title">Mo. service fee</div>
                        </div>
                        <div className="separator"></div>
                      </div>
                      <div className="other-fees mobile-view">
                        <h2 className="title">{lender.Other_fees}</h2>
                      </div>
                    </div>

                    <div className="overview loan-amount">
                      <h2 className="value">
                        <span className="up-to">Up to</span>
                        {NGNCurrency.format(lender.Maximum_loan_amount)}
                      </h2>
                      <div className="title">Loan Amount</div>
                    </div>
                    {/* <div className="title">
                      Other Fees: {lender.Other_fees || "-"}
                    </div> */}

                    <div className="overview loan-btn">
                      <Button
                        disabled={
                          selectedLender.id === lender.id || lender.isApplied
                        }
                        onClick={() => {
                          setShowConfirmDialog(true);
                          setSelectedLender({ ...lender });
                        }}
                        appearance="primary"
                        className="green-btn"
                      >
                        Choose this Loan
                      </Button>
                    </div>
                  </div>
                  <div className="other-fees hide-mobile">
                    <h2 className="title">{lender.Other_fees}</h2>
                  </div>
                  {/* <h4 className="title description">
                    {lender.Description || "-"}
                  </h4> */}
                </div>
              ))}
            </div>
          </>
        ) : !appliedSuccess ? (
          <div className="page-center-msg">
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              Unfortunately, we did not find any lender that is willing to
              provide you a loan. <br />
              <div style={{ fontSize: '11px', marginTop: '20px' }}>
                We are working hard to add more lenders onto the platform so
                that this doesn't happen again in the future
              </div>
            </p>
          </div>
        ) : null}
      </div>

      <Modal
        backdrop="static"
        role="alertdialog"
        className="confirm-dialog"
        open={showConfirmDialog}
        onClose={() => {
          setShowConfirmDialog(false);
        }}
        size="xs"
      >
        <Modal.Body>
          <div className="confirm-msg">
            <InfoOutlineIcon style={{ color: '#ffb300', fontSize: 24 }} />
            Your are now applying for {selectedLender.Vendor_Name}'s loan
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={() => {
              setShowConfirmDialog(false);
              setShowApplySuccessFailModal(true);
              applyToLender(selectedLender.id);
            }}
            appearance="primary"
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setSelectedLender({});
              setShowConfirmDialog(false);
            }}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        open={showApplySuccessFailModal}
        backdrop="static"
        className="success-modal"
        onClose={() => {
          setShowApplySuccessFailModal(false);
        }}
      >
        <Modal.Body>
          {isLoading ? (
            <Loading isHideOverlay={true} message="Loading..." />
          ) : (
            renderMessage()
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={() => {
              closeApplySuccessFailModal();
            }}
            appearance="primary"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BeforeSignup;
