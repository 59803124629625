/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { Modal, Button } from 'rsuite';
import axios from 'axios';
import { useAuth } from '../../utils/AuthContext';
import Loading from './../../shared/Loading/Loading';
import BeforeSignup from './BeforeSignup/BeforeSignup';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import moment from 'moment';

import './BorrowersDashboard.scss';

const BorrowersDashboard = (props) => {
  const { isBeforeSignup, borrowerEmail, lenders, dealID } = props;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [lenderList, setLendersList] = useState([]);
  const [selectedLender, setSelectedLender] = useState({});
  const [latestDeal, setLatestDeal] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isLoadingLenders, setLoadingLenders] = useState(false);
  const [message, setMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showLendersError, setShowLendersError] = useState(false);
  const [showNoApplications, setShowNoApplications] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    getLendersList();
  }, []);

  const sortByBlueText = (_lenders) => {
    _lenders.sort((a, b) => {
      if (a.Show_blue_text === null) {
        return 1;
      }

      if (b.Show_blue_text === null) {
        return -1;
      }
      if (a.Show_blue_text < b.Show_blue_text) {
        return 1;
      }
      if (a.Show_blue_text > b.Show_blue_text) {
        return -1;
      }
      return 0;
    });
    return _lenders;
  };

  const getLendersList = async () => {
    setLoadingLenders(true);
    try {
      const { data } = await axios.post(
        'server/main_app_function/api/v1/getBorrowerApplication',
        { borrowerEmail: user.email_id },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const responseData = data.data;
      if (responseData.status === 'success') {
        setLatestDeal(responseData.latestDeal);
        if (responseData.lenders && responseData.lenders.length) {
          let _lenders = responseData.lenders.filter((lender) => {
            if (!lender.Borrowers_Count || !lender.Borrowers_Limit) {
              return true;
            }
            if (lender.Borrowers_Count <= lender.Borrowers_Limit) {
              return true;
            }
            return false;
          });
          _lenders = sortByBlueText(_lenders);
          setLendersList(_lenders);
        }
      } else if (
        responseData?.status === 'ok' &&
        responseData?.message === 'Aplications not found'
      ) {
        setShowNoApplications(true);
      } else {
        setShowLendersError(true);
      }
      setLoadingLenders(false);
    } catch (err) {
      setShowLendersError(true);
      setLoadingLenders(false);
    }
  };

  const applyToLender = async (lenderID) => {
    setLoading(true);
    setShowError(false);
    try {
      const { data } = await axios.post(
        '/assignLender',
        {
          dealID: latestDeal.id,
          lenderID,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data.status === 'fail') {
        setShowError(true);
        setSelectedLender({});
      } else {
        setLoading(false);
        setMessage(data.message);
        setTimeout(() => {
          getLendersList();
        }, 1500);
      }
    } catch (err) {
      setLoading(false);
      setSelectedLender({});
      setMessage('Something went wrong!');
      setShowError(true);
    }
  };

  const closeDetailsModal = () => {
    if (!showError) {
      const _lenderList = lenderList.map((lender) => {
        if (lender.id === selectedLender.id) {
          return {
            ...lender,
            isApplied: true,
          };
        }
        return lender;
      });
      setLendersList(_lenderList);
    }
    setSelectedLender({});
    setShowDetailsModal(false);
  };

  const NGNCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'NGN',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const renderMessage = () => {
    if (showError) {
      return (
        <h3 className="message error">
          <InfoOutlineIcon />
          {message}
        </h3>
      );
    }
    return (
      <>
        <h3 className="message">
          You have now applied for a loan from {selectedLender.Vendor_Name}.
        </h3>
        <h3 className="message">
          Please allow a maximum of 2 business days for the lender to contact
          you and finalise your application.
        </h3>
      </>
    );
  };

  const renderLendersNotFoundMessage = () => {
    return (
      <div className="page-center-msg">
        <p
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          Unfortunately, we did not find any lender that is willing to provide
          you a loan. <br />
          <div style={{ fontSize: '11px', marginTop: '20px' }}>
            We are working hard to add more lenders onto the platform so that
            this doesn't happen again in the future
          </div>
        </p>
      </div>
    );
  };

  const renderLendersErrorMessage = () => {
    return (
      <div className="page-center-msg">
        <p
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          Something went wrong !!!
          <br />
          <span style={{ fontSize: '11px' }}>Please try after sometime.</span>
        </p>
      </div>
    );
  };

  const calculateAppliedDays = (createdTime) => {
    let start = moment(createdTime);
    let end = moment();
    let weekdayCounter = 0;

    while (start <= end) {
      if (start.format('ddd') !== 'Sat' && start.format('ddd') !== 'Sun') {
        weekdayCounter++;
      }
      start = moment(start, 'YYYY-MM-DD').add(1, 'days');
    }
    return weekdayCounter;
  };

  if (isBeforeSignup) {
    return (
      <BeforeSignup
        lenders={lenders}
        latestDeal={latestDeal}
        dealID={dealID}
        borrowerEmail={borrowerEmail}
      />
    );
  }

  if (isLoadingLenders) {
    return (
      <div className="page-container">
        <div className="borrower-dashboard-page">
          <Loading isHideOverlay={true} message="Loading..." />
        </div>
      </div>
    );
  }

  if (showLendersError) {
    return (
      <div className="page-container">
        <div className="borrower-dashboard-page">
          {renderLendersErrorMessage()}
        </div>
      </div>
    );
  }

  if (showNoApplications) {
    return (
      <div className="page-container">
        <div className="borrower-dashboard-page">
          <div className="page-center-msg">
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              No applications found!
              <br />
              <span style={{ fontSize: '11px' }}>
                Probably your application was approved and that's why there is
                no data displayed here.
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (
    latestDeal.Lenders &&
    latestDeal.Created_Time &&
    calculateAppliedDays(latestDeal.Created_Time) <= 1
  ) {
    return (
      <div className="page-container">
        <div className="borrower-dashboard-page">
          <div className="page-center-msg">
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              You already applied for a loan on{' '}
              {latestDeal?.Created_Time.split('T')[0]}.
              <br />
              <span style={{ fontSize: '11px' }}>
                Please allow a maximum of 2 business days for the lender to
                contact you and finalise your application.
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="borrower-dashboard-page">
        {lenderList.length > 0 ? (
          <>
            <div className="recommend-wrapper">
              {lenderList.map((lender) => (
                <div
                  className={`recommend-box ${
                    lender.Show_blue_text ? 'highlight' : ''
                  }`}
                  key={lender.id}
                >
                  {lender.Show_blue_text && (
                    <div className="highlight-title">
                      {lender.Show_blue_text.toUpperCase()}
                    </div>
                  )}
                  <div className="recommed-overview">
                    <div className="overview logo">
                      <img
                        width="180"
                        src={lender.Lender_Public_Logo_URL}
                        alt="Logo"
                      />
                      {/* <div className="title">{lender.Vendor_Name}</div> */}
                    </div>

                    <div className="overview-info">
                      <div className="overview-info-wrapper">
                        <div className="overview interest-rate">
                          <h2 className="value">
                            {lender.Maximum_Monthly_Interest_Rate}% / mo.
                          </h2>
                          <div className="title">Interest rate</div>
                        </div>
                        <div className="separator"></div>

                        <div className="overview org-fees">
                          <h2 className="value">{lender.Origination_fee}</h2>
                          <div className="title">Origination fee</div>
                        </div>
                        <div className="separator"></div>

                        <div className="overview serivce-fees">
                          <h2 className="value">{lender.Mo_Service_Fee}</h2>
                          <div className="title">Mo. service fee</div>
                        </div>
                        <div className="separator"></div>
                      </div>
                      <div className="other-fees mobile-view">
                        <h2 className="title">{lender.Other_fees}</h2>
                      </div>
                    </div>

                    <div className="overview loan-amount">
                      <h2 className="value">
                        <span className="up-to">Up to</span>
                        {NGNCurrency.format(lender.Maximum_loan_amount)}
                      </h2>
                      <div className="title">Loan Amount</div>
                    </div>
                    {/* <div className="title">
                   Other Fees: {lender.Other_fees || "-"}
                 </div> */}

                    <div className="overview loan-btn">
                      <Button
                        disabled={
                          selectedLender.id === lender.id || lender.isApplied
                        }
                        onClick={() => {
                          setShowConfirmDialog(true);
                          setSelectedLender({ ...lender });
                        }}
                        appearance="primary"
                        className="green-btn"
                      >
                        Choose this Loan
                      </Button>
                    </div>
                  </div>
                  <div className="other-fees hide-mobile">
                    <h2 className="title">{lender.Other_fees}</h2>
                  </div>
                  {/* <h4 className="title description">
                 {lender.Description || "-"}
               </h4> */}
                </div>
              ))}
            </div>
          </>
        ) : (
          renderLendersNotFoundMessage()
        )}
      </div>

      <Modal
        backdrop="static"
        role="alertdialog"
        className="confirm-dialog"
        open={showConfirmDialog}
        onClose={() => {
          setShowConfirmDialog(false);
        }}
        size="xs"
      >
        <Modal.Body>
          <div className="confirm-msg">
            <InfoOutlineIcon style={{ color: '#ffb300', fontSize: 24 }} />
            Your are now applying for {selectedLender.Vendor_Name}'s loan
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={() => {
              setShowConfirmDialog(false);
              setShowDetailsModal(true);
              applyToLender(selectedLender.id);
            }}
            appearance="primary"
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setSelectedLender({});
              setShowConfirmDialog(false);
            }}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        open={showDetailsModal}
        backdrop="static"
        className="success-modal"
        onClose={() => {
          setShowDetailsModal(false);
        }}
      >
        <Modal.Body>
          {isLoading ? (
            <Loading isHideOverlay={true} message="Loading..." />
          ) : (
            renderMessage()
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            onClick={() => {
              closeDetailsModal();
            }}
            appearance="primary"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BorrowersDashboard;
