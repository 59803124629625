/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useAuth } from '../../../utils/AuthContext';
import {
  InputGroup,
  Input,
  Table,
  Pagination,
  Dropdown,
  Modal,
  Button,
} from 'rsuite';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import SearchIcon from '@rsuite/icons/Search';
import debounce from 'lodash/debounce';
import CsvDownloadButton from 'react-json-to-csv';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import Loading from '../../../shared/Loading/Loading';
import './LenderDashboard.scss';

const { Column, HeaderCell, Cell } = Table;

const LenderDashboard = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingStatus, setLoadingStatus] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showStatusConfirmModal, setShowStatusConfirmModal] = useState(false);
  const [showExportConfirmModal, setShowExportConfirmModal] = useState(false);
  const [applicantsData, setApplicantsData] = useState([]);
  const [applicantsDataAll, setApplicantsDataAll] = useState([]);
  const [selectedApplicant, setSelectedApplicant] = useState({});
  const [isApplicantsError, setApplicantsError] = useState(false);
  const [isStatusChangedType, setStatusChangedType] = useState('');
  const [isOverviewLoading, setOverviewLoading] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [exportData, setExportData] = useState([]);
  const [errorMesage, setErrorMessage] = useState('');

  const { user } = useAuth();

  const getApplicantOverview = async () => {
    try {
      setOverviewLoading(true);
      const { data } = await axios.post(
        '/server/main_app_function/api/v1/getLenderStats',
        {
          lenderEmail: user.email_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data?.status === 'success') {
        setOverviewData(data?.statsData || {});
      } else {
        setOverviewData({});
      }
      setOverviewLoading(false);
    } catch (error) {
      setOverviewLoading(false);
    }
  };

  const getApplicanDownload = async () => {
    try {
      await axios.post(
        '/server/main_app_function/api/v1/createLenderAction',
        {
          dealID: '',
          lenderEmail: user.email_id,
          actionType: 'Data Exported',
          borrowerEmail: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {}
  };

  const getApplicantsList = async () => {
    try {
      setLoading(true);
      setApplicantsError(false);
      const { data } = await axios.post(
        '/server/main_app_function/api/v1/getApplications',
        {
          catalystUserID: user.user_id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data?.status === 'success') {
        if (data?.data?.status === 'error') {
          setApplicantsData([]);
          setApplicantsDataAll([]);
          setExportData([]);
          setErrorMessage(data?.data?.message || 'Something went wrong!');
        } else {
          const _applicantData = data?.data?.data || [];
          setApplicantsData(_applicantData);
          setApplicantsDataAll(_applicantData);
          const _exportData = JSON.parse(JSON.stringify(_applicantData));
          _exportData.map((d) => {
            delete d.status;
            delete d.Status;
          });
          setExportData(_exportData);
        }
      } else if (data?.data?.status === 'error') {
        setApplicantsData([]);
        setApplicantsDataAll([]);
        setExportData([]);
        setErrorMessage(data?.data?.message || 'Something went wrong!');
      } else {
        setApplicantsData([]);
        setApplicantsDataAll([]);
        setExportData([]);
        setErrorMessage('Something went wrong!');
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.message || 'Something went wrong!');
      setLoading(false);
    }
  };

  useEffect(() => {
    getApplicantOverview();
    getApplicantsList();
  }, [user.user_id]);

  const updateApplicantStatus = async (
    _selectedApplicant = {},
    status = ''
  ) => {
    setStatusChangedType('');
    setLoadingStatus(true);
    try {
      const { data } = await axios.post(
        '/server/main_app_function/api/v1/createLenderAction',
        {
          dealID: _selectedApplicant.deal_id,
          lenderEmail: user.email_id,
          actionType: status ? status : _selectedApplicant.new_status,
          borrowerEmail: _selectedApplicant.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data?.status === 'success') {
        setSelectedApplicant({
          ..._selectedApplicant,
          status: status ? status : _selectedApplicant.new_status,
        });
        setStatusChangedType('SUCCESS');

        if (status === 'Viewed') {
          const _applicantsDataAll = applicantsDataAll.map((applicant) => {
            if (applicant.contact_id !== _selectedApplicant.contact_id) {
              applicant.status = status;
            }
            return applicant;
          });
          const _applicantsData = applicantsData.map((applicant) => {
            if (applicant.contact_id !== _selectedApplicant.contact_id) {
              applicant.status = status;
            }
            return applicant;
          });
          setApplicantsDataAll(_applicantsDataAll);
          setApplicantsData(_applicantsData);
        } else {
          setShowDetailsModal(false);
        }
      } else {
        setStatusChangedType('ERROR');
      }
      setLoadingStatus(false);
    } catch (error) {
      setStatusChangedType('ERROR');
      setLoadingStatus(false);
    }
  };

  const onStatusChangedSuccess = () => {
    const _applicantsDataAll = applicantsDataAll.filter((applicant) => {
      return applicant.contact_id !== selectedApplicant.contact_id;
    });
    const _applicantsData = applicantsData.filter((applicant) => {
      return applicant.contact_id !== selectedApplicant.contact_id;
    });
    setApplicantsDataAll(_applicantsDataAll);
    setApplicantsData(_applicantsData);
  };

  const handleSearch = (query, _applicantsDataAll) => {
    const _applicantsData = _applicantsDataAll.filter((applicant) => {
      return (
        applicant?.first_name?.toLowerCase()?.indexOf(query?.toLowerCase()) >=
          0 ||
        applicant?.last_name?.toLowerCase()?.indexOf(query?.toLowerCase()) >=
          0 ||
        applicant?.phone?.indexOf(query) >= 0 ||
        applicant?.BVN?.indexOf(query) >= 0 ||
        applicant?.email?.toLowerCase()?.indexOf(query?.toLowerCase()) >= 0
      );
    });
    setApplicantsData(_applicantsData);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce((query, _applicantsDataAll) => {
      handleSearch(query, _applicantsDataAll);
    }, 500),
    []
  );

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const getTableData = () => {
    const data = applicantsData.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });

    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const ActionCell = ({ rowData, ...props }) => (
    <Cell {...props} style={{ padding: '5px' }}>
      <Button style={{ backgroundColor: '#fe9870', color: '#fff' }}>
        View
      </Button>
    </Cell>
  );

  const ApplicationDateCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        {rowData[dataKey] ? moment(rowData[dataKey]).format('DD.MM.YYYY') : ''}
      </Cell>
    );
  };

  const NGNCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'NGN',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const NGNNumber = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const isNumber = (value) => {
    return typeof value === 'number';
  };

  const [columnDefs] = useState([
    {
      field: 'last_name',
      title: 'Last Name',
      sortable: true,
      minWidth: 200,
      flexGrow: 1,
      resizable: true,
    },
    {
      field: 'first_name',
      title: 'First Name',
      sortable: true,
      minWidth: 200,
      flexGrow: 1,
      resizable: true,
    },
    {
      field: 'Application_Date',
      title: 'Application Date',
      sortable: true,
      resizable: true,
      minWidth: 200,
      flexGrow: 1,
      cellRenderer: <ApplicationDateCell dataKey="Application_Date" />,
    },
    {
      field: 'Action',
      title: 'Action',
      sortable: false,
      resizable: true,
      minWidth: 200,
      flexGrow: 1,
      cellRenderer: <ActionCell />,
    },
  ]);

  if (errorMesage) {
    return (
      <div className="page-container">
        <div className="dashboard-page">
          <div className="page-center-msg">
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              <span style={{ fontSize: '14px' }}>{errorMesage}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (isApplicantsError) {
    return (
      <div className="page-container">
        <div className="dashboard-page">
          <div className="page-center-msg">
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              Unfortunately, we did not find any applicants. <br />
              <span style={{ fontSize: '11px' }}>
                We are working hard to add more applicants onto the platform so
                that this doesn't happen again in the future
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="dashboard-page">
        <h3 className="page-title">Overview</h3>
        <div className="stats-wrapper">
          {isOverviewLoading && <Loading message="" isHideOverlay={true} />}
          <div className="stats-box">
            <h4 className="title">Leads Credits Left</h4>
            <h2 className="value">
              {NGNNumber.format(
                isNumber(overviewData.borrowersLimit) &&
                  isNumber(overviewData.borrowersCounter)
                  ? overviewData.borrowersLimit - overviewData.borrowersCounter
                  : 0
              )}
            </h2>
          </div>
          <div className="stats-box">
            <h4 className="title">Approved Applicants</h4>
            <h2 className="value">
              {NGNNumber.format(
                overviewData.approvedCounter ? overviewData.approvedCounter : 0
              )}
            </h2>
          </div>
          <div className="stats-box">
            <h4 className="title">Rejected Applicants</h4>
            <h2 className="value">
              {NGNNumber.format(
                overviewData.rejectedCounter ? overviewData.rejectedCounter : 0
              )}
            </h2>
          </div>
        </div>

        <div className="filters-wrapper">
          <div className="tag-filters">
            <h3 className="page-title">Applicant Details</h3>
          </div>
          <div className="search-container">
            <div className="search-wrapper">
              <InputGroup size="md" inside>
                <Input
                  placeholder="Search"
                  onChange={(value) => {
                    debouncedHandleSearch(value, applicantsDataAll);
                  }}
                />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </div>

            <Button
              disabled={isLoading || !applicantsData.length}
              className="download-btn"
              onClick={() => {
                setShowExportConfirmModal(true);
                getApplicanDownload();
              }}
              appearance="primary"
            >
              <FileDownloadIcon />
              <span className="download-text">Export as CSV</span>
            </Button>
          </div>
        </div>

        <div className="table-wrapper">
          <Table
            data={getTableData()}
            minHeight={400}
            fillHeight={true}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={isLoading}
            onRowClick={(rowData) => {
              if (!rowData.status) {
                updateApplicantStatus(rowData, 'Viewed');
              } else {
                setSelectedApplicant(rowData);
              }
              setShowDetailsModal(true);
            }}
          >
            {columnDefs.map((column) => {
              return (
                <Column
                  key={column.field}
                  width={column.width}
                  minWidth={column.minWidth}
                  align={column.align || 'left'}
                  sortable={column.sortable}
                  resizable={column.resizable}
                  flexGrow={column.flexGrow}
                >
                  <HeaderCell>{column.title}</HeaderCell>
                  {column.cellRenderer ? (
                    column.cellRenderer
                  ) : (
                    <Cell dataKey={column.field} />
                  )}
                </Column>
              );
            })}
          </Table>
        </div>
        <div className="pagination-wrapper">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={applicantsData.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
      <Modal
        open={showDetailsModal}
        backdrop="static"
        className="details-modal"
        onClose={() => {
          setShowDetailsModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Applicant Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-items">
            <div className="list-items">
              <div className="list-title">Status</div>
              <div className="list-item">
                <div className="label">
                  <Dropdown
                    className="primary-dropdown"
                    title={selectedApplicant.status || 'Select Status'}
                    activeKey={selectedApplicant.status}
                    loading={isLoadingStatus}
                    onSelect={(value) => {
                      setStatusChangedType('');
                      setShowStatusConfirmModal(true);
                      setSelectedApplicant({
                        ...selectedApplicant,
                        new_status: value,
                      });
                    }}
                  >
                    <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                    <Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="list-title">Personal Information</div>
            <div className="list-item">
              <div className="label">First name</div>
              <div className="value">{selectedApplicant.first_name}</div>
            </div>
            <div className="list-item">
              <div className="label">Last name</div>
              <div className="value">{selectedApplicant.last_name}</div>
            </div>
            <div className="list-item">
              <div className="label">Email</div>
              <div className="value">{selectedApplicant.email}</div>
            </div>
            <div className="list-item">
              <div className="label">Phone</div>
              <div className="value">{selectedApplicant.phone}</div>
            </div>
            <div className="list-item">
              <div className="label">BVN</div>
              <div className="value">{selectedApplicant.BVN}</div>
            </div>
            <div className="list-item">
              <div className="label">Gender</div>
              <div className="value">{selectedApplicant.gender}</div>
            </div>
            <div className="list-item">
              <div className="label">Level of Education</div>
              <div className="value">{selectedApplicant.Education_Level}</div>
            </div>
            <div className="list-item">
              <div className="label">Relationship Status</div>
              <div className="value">
                {selectedApplicant.Relationship_Status}
              </div>
            </div>
            <div className="list-item">
              <div className="label">Do you have children?</div>
              <div className="value">
                {selectedApplicant.Do_You_Have_Children}
              </div>
            </div>
            <div className="list-item">
              <div className="label">How many?</div>
              <div className="value">{selectedApplicant['How_Many?']}</div>
            </div>
          </div>
          <div className="list-items">
            <div className="list-title">Address Information</div>
            <div className="list-item">
              <div className="label">Street Address</div>
              <div className="value">
                {selectedApplicant.Your_Current_Street_Address}
              </div>
            </div>
            <div className="list-item">
              <div className="label">Local Government Area</div>
              <div className="value">
                {selectedApplicant.Local_Government_Area_LGA}
              </div>
            </div>
            <div className="list-item">
              <div className="label">State</div>
              <div className="value">{selectedApplicant.Residence_State}</div>
            </div>
          </div>

          <div className="list-items">
            <div className="list-title">Employment Status</div>
            <div className="list-item">
              <div className="label">Employer Company Name</div>
              <div className="value">{selectedApplicant.Employer_Name}</div>
            </div>
            <div className="list-item">
              <div className="label">Employer Office Address</div>
              <div className="value">
                {selectedApplicant.Employer_Office_Address}
              </div>
            </div>
            <div className="list-item">
              <div className="label">Employment Status</div>
              <div className="value">{selectedApplicant.Employment_status}</div>
            </div>
            <div className="list-item">
              <div className="label">Current Employment Start Date</div>
              <div className="value">
                {selectedApplicant.Current_Employment_Start_Date}
              </div>
            </div>
            <div className="list-item">
              <div className="label">Salary Amount</div>
              <div className="value">
                {NGNCurrency.format(selectedApplicant.Salary_Amount)}
              </div>
            </div>
            <div className="list-item">
              <div className="label">Frequency of Salary Amount Paid</div>
              <div className="value">{selectedApplicant.Salary_Frequency}</div>
            </div>
          </div>

          <div className="list-items">
            <div className="list-title">Credit History</div>
            <div className="list-item">
              <div className="label">Do you currently have active loan?</div>
              <div className="value">{selectedApplicant.Active_Loan}</div>
            </div>
            <div className="list-item">
              <div className="label">Who is the Lender?</div>
              <div className="value">{selectedApplicant.Who_is_the_lender}</div>
            </div>
            <div className="list-item">
              <div className="label">
                How much is your monthly loan repayment?
              </div>
              <div className="value">
                {NGNCurrency.format(
                  selectedApplicant.How_much_is_your_monthly_loan_repayment
                )}
              </div>
            </div>
            <div className="list-item">
              <div className="label">Have you taken other loans before?</div>
              <div className="value">
                {selectedApplicant.Have_you_taken_other_loans_before}
              </div>
            </div>
            <div className="list-item">
              <div className="label">
                Which lenders have you taken a loan from?
              </div>
              <div className="value">
                {selectedApplicant.Which_lenders_have_you_taken_a_loan_from}
              </div>
            </div>
            <div className="list-item">
              <div className="label">
                Have you ever defaulted on a loan payment?
              </div>
              <div className="value">
                {selectedApplicant.Have_you_ever_defaulted_on_a_loan_repayment}
              </div>
            </div>
            <div className="list-item">
              <div className="label">What year did you default on a loan?</div>
              <div className="value">
                {selectedApplicant.What_year_did_you_default_on_a_loan}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowDetailsModal(false);
            }}
            appearance="primary"
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        role="alertdialog"
        className="confirm-dialog"
        open={showStatusConfirmModal}
        onClose={() => {
          setShowStatusConfirmModal(false);
        }}
        size="xs"
      >
        <Modal.Body>
          {isStatusChangedType === '' && (
            <div className="confirm-msg">
              <InfoOutlineIcon style={{ color: '#ffb300', fontSize: 24 }} />
              Are you sure want to change the status to{' '}
              {selectedApplicant.new_status}?
            </div>
          )}
          {isStatusChangedType === 'SUCCESS' && (
            <div className="confirm-msg">
              <InfoOutlineIcon style={{ color: '#ffb300', fontSize: 24 }} />
              Applicant status changed successfully.
            </div>
          )}
          {isStatusChangedType === 'ERROR' && (
            <div className="confirm-msg">
              <InfoOutlineIcon style={{ color: '#ffb300', fontSize: 24 }} />
              Failed changing the applicant status. Please try again.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {isStatusChangedType === '' ? (
            <>
              <Button
                disabled={isLoadingStatus}
                onClick={() => {
                  updateApplicantStatus(selectedApplicant);
                }}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  setShowStatusConfirmModal(false);
                }}
                appearance="subtle"
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              disabled={isLoadingStatus}
              onClick={() => {
                setShowStatusConfirmModal(false);
                if (isStatusChangedType === 'SUCCESS') {
                  onStatusChangedSuccess();
                }
              }}
              appearance="primary"
            >
              Ok
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Export Confirm dialog */}
      <Modal
        backdrop="static"
        role="alertdialog"
        className="confirm-dialog"
        open={showExportConfirmModal}
        onClose={() => {
          setShowExportConfirmModal(false);
        }}
        size="xs"
      >
        <Modal.Body>
          <div className="confirm-msg">
            <InfoOutlineIcon style={{ color: '#ffb300', fontSize: 24 }} />
            Are you sure you want to export the data?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <CsvDownloadButton
            className="csv-download-btn"
            data={exportData}
            filename={`applicants-${new Date().toISOString()}`}
          >
            <Button
              appearance="primary"
              onClick={() => {
                setShowExportConfirmModal(false);
              }}
            >
              Confirm
            </Button>
          </CsvDownloadButton>
          <Button
            onClick={() => {
              setShowExportConfirmModal(false);
            }}
            appearance="subtle"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LenderDashboard;
