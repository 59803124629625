/* eslint-disable no-useless-escape */
import { useState, useRef, forwardRef, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../shared/Loading/Loading';
import Offers from './Offers/Offers';
import ActiveApplication from '../ActiveApplication/ActiveApplication';

import {
  Steps,
  Button,
  Message,
  Form,
  RadioGroup,
  Radio,
  SelectPicker,
  DatePicker,
  Schema,
} from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';
import statesLocals from './../../json/states_and_localGov.json';
import '../../App.scss';
import './Application.scss';

const FormControl = forwardRef((props, ref) => {
  const { name, message, label, accepter, error, ...rest } = props;
  return (
    <Form.Group
      controlId={`${name}-10`}
      ref={ref}
      className={error ? 'has-error' : ''}
    >
      <Form.ControlLabel>{label} </Form.ControlLabel>
      <Form.Control
        size="lg"
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...rest}
      />
      <Form.HelpText>{message}</Form.HelpText>
    </Form.Group>
  );
});

const Application = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [responseSubmit, setResponseSubmit] = useState('');
  const [errorSubmit, setErrorSubmit] = useState('');
  const [hasActiveApplication, setHasActiveApplication] = useState(false);
  const [lenders, setLenders] = useState([]);
  const [dealID, setDealID] = useState('');

  const [relationshipList] = useState([
    {
      label_value: 'Single',
      actual_value: 'Single',
    },
    {
      label_value: 'Married',
      actual_value: 'Married',
    },
    {
      label_value: 'Engagement',
      actual_value: 'Engagement',
    },
    {
      label_value: 'Widow',
      actual_value: 'Widow',
    },
  ]);

  const [educationList] = useState([
    {
      label_value: 'None',
      actual_value: 'None',
    },
    {
      label_value: 'Higher_National_Diploma',
      actual_value: 'Higher National Diploma',
    },
    {
      label_value: 'Postgraduate_Diploma',
      actual_value: 'Postgraduate Diploma',
    },
    {
      label_value: 'Bachelors_Degree',
      actual_value: "Bachelor's Degree",
    },
    {
      label_value: 'Masters_Degree',
      actual_value: "Master's Degree",
    },
    {
      label_value: 'Doctorate_Degree',
      actual_value: 'Doctorate Degree',
    },
  ]);

  const [childrensList] = useState([
    {
      label_value: '1',
      actual_value: '1',
    },
    {
      label_value: '2',
      actual_value: '2',
    },
    {
      label_value: '3',
      actual_value: '3',
    },
    {
      label_value: 'more_than_three',
      actual_value: 'More than 3',
    },
  ]);

  const [statesList, setStatesList] = useState([]);
  const [localAreaList, setLocalAreaList] = useState([]);

  const [employmentStatusList] = useState([
    {
      label_value: 'Permanently_Employed',
      actual_value: 'Permanently Employed',
    },
    {
      label_value: 'Temporarily_Employed',
      actual_value: 'Temporarily Employed',
    },
    {
      label_value: 'Self_Employed',
      actual_value: 'Self-Employed',
    },
    {
      label_value: 'Gig_Worker',
      actual_value: 'Gig Worker',
    },
    {
      label_value: 'Unmployed',
      actual_value: 'Unmployed',
    },
  ]);

  const [salaryFrequencyList] = useState([
    {
      label_value: 'Weekly',
      actual_value: 'Weekly',
    },
    {
      label_value: 'BiWeekly',
      actual_value: 'Bi-Weekly',
    },
    {
      label_value: 'Monthly',
      actual_value: 'Monthly',
    },
  ]);

  const [defaultYearsList] = useState([
    {
      label_value: '0',
      actual_value: 'Defaulted over 1 year ago',
    },
    {
      label_value: '1',
      actual_value: 'Defaulted over 2 years ago',
    },
    {
      label_value: '2',
      actual_value: 'Defaulted over 3 years ago',
    },
    {
      label_value: '3',
      actual_value: 'Defaulted over 4 years ago',
    },
    {
      label_value: '4',
      actual_value: 'Defaulted over 5 years ago',
    },
  ]);

  const [lendersList] = useState([
    {
      label_value: 'PageFinancials',
      actual_value: 'Page Financials',
    },
    {
      label_value: 'Renmoney',
      actual_value: 'Renmoney',
    },
    {
      label_value: 'Carbon',
      actual_value: 'Carbon',
    },
    {
      label_value: 'Branch',
      actual_value: 'Branch',
    },
    {
      label_value: 'Fairmoney',
      actual_value: 'Fairmoney',
    },
    {
      label_value: 'Palmcredit',
      actual_value: 'Palmcredit',
    },
    {
      label_value: 'ZitraInvestments',
      actual_value: 'Zitra Investments',
    },
    {
      label_value: 'Pennylender',
      actual_value: 'Pennylender',
    },
    {
      label_value: 'OtherLender',
      actual_value: 'Other Lender',
    },
  ]);

  const personalFormRef = useRef();
  const addressFormRef = useRef();
  const employementFormRef = useRef();
  const creditHistoryFormRef = useRef();

  const [personalDetails, setPersonalDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    BVN: '',
    gender: 'Male',
    education: '',
    relationship_status: '',
    do_you_have_children: 'Yes',
    how_many: '',
  });

  const [addressDetails, setAddressDetails] = useState({
    street: '',
    local_government: '',
    state: '',
  });

  const [employmentDetails, setEmploymentDetails] = useState({
    employer_name: '',
    employer_address: '',
    employment_status: '',
    employment_start_date: null,
    salary: '',
    frequency_of_salary: '',
  });

  const [creditHistoryDetails, setCreditHistoryDetails] = useState({
    any_active_loan: 'Yes',
    active_loan_lender_name: '',
    monthly_loan_repayment: '',
    any_loans_before: 'Yes',
    default_on_loan_payment: 'No',
    default_years_ago: '',
  });

  const [personalModel, setPersonalModel] = useState(
    Schema.Model({
      firstname: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      lastname: Schema.Types.StringType().isRequired('This field is required.'),
      email: Schema.Types.StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
      phone: Schema.Types.StringType()
        .isRequired('This field is required.')
        .pattern(
          /^(?:(?:(?:\+?234(?:\h1)?|01)\h*)?(?:\(\d{3}\)|\d{3})|\d{4})(?:\W*\d{3})?\W*\d{4}$/,
          'Please enter valid mobile number.'
        ),
      BVN: Schema.Types.NumberType().isRequired('This field is required.'),
      education: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      relationship_status: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      how_many: Schema.Types.StringType().isRequired('This field is required.'),
    })
  );

  const [addressModel] = useState(
    Schema.Model({
      street: Schema.Types.StringType().isRequired('This field is required.'),
      local_government: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      state: Schema.Types.StringType().isRequired('This field is required.'),
    })
  );

  const [employmentModel] = useState(
    Schema.Model({
      employer_name: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      employer_address: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      employment_status: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      employment_start_date: Schema.Types.DateType().isRequired(
        'This field is required.'
      ),
      salary: Schema.Types.StringType().isRequired('This field is required.'),
      frequency_of_salary: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
    })
  );

  const [creditHistoryModel, setCreditHistoryModel] = useState(
    Schema.Model({
      active_loan_lender_name: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
      monthly_loan_repayment: Schema.Types.StringType().isRequired(
        'This field is required.'
      ),
    })
  );

  const savePersonalAndNext = async () => {
    if (!personalFormRef.current.check()) {
      return;
    }

    setLoading(true);

    try {
      const { data } = await axios.post(
        '/checkBorrower',
        {
          email: personalDetails.email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data.status === 'ACTIVE') {
        setHasActiveApplication(true);
        setLoading(false);
      } else {
        setLoading(false);
        setStep(1);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [step]);

  const saveAddressAndNext = () => {
    if (!addressFormRef.current.check()) {
      return;
    }
    setStep(2);
  };

  const saveEmploymentAndNext = () => {
    if (!employementFormRef.current.check()) {
      return;
    }
    setStep(3);
  };

  const saveCreditHistoryAndNext = () => {
    if (!creditHistoryFormRef.current.check()) {
      return;
    }
    submitApplication();
  };

  useEffect(() => {
    const states = Object.keys(statesLocals).map((state) => {
      return {
        label_value: state,
        actual_value: state,
      };
    });
    setStatesList(states);
  }, []);

  const submitApplication = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        '/application',
        {
          personalDetails,
          addressDetails,
          employmentDetails,
          creditHistoryDetails,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (data.status === 'success') {
        setResponseSubmit('ok');
        setDealID(data.data?.details?.dealID);
        setLenders(data?.data?.details?.lenders || []);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 409) {
        setLoading(false);
        setErrorSubmit('Email is already in use!');
      }
    }
  };

  if (loading) {
    return <Loading message="Processing..." />;
  }

  if (hasActiveApplication) {
    return <ActiveApplication />;
  }

  if (responseSubmit) {
    return (
      <Offers
        lenders={lenders}
        dealID={dealID}
        borrowerEmail={personalDetails.email}
      />
    );
  }

  return (
    <div className="creditnigeria-app application-page">
      <div className="container">
        <div className="page-body">
          {errorSubmit && <Message type="error">{errorSubmit}</Message>}
          <Steps current={step}>
            <Steps.Item title="Personal Information" />
            <Steps.Item title="Address Information" />
            <Steps.Item title="Employment Status" />
            <Steps.Item title="Credit History" />
          </Steps>
          <div className="user-reg-form">
            {step === 0 && (
              <div className="personal-info-form">
                <h4 className="form-title">Personal Information</h4>
                <h4 className="form-info-title">
                  Always provide accurate details. Lenders will always verify
                  your details.
                </h4>
                <Form
                  layout="inline"
                  ref={personalFormRef}
                  model={personalModel}
                  formValue={personalDetails}
                  onChange={setPersonalDetails}
                >
                  <FormControl name="firstname" label="Your First Name" />
                  <FormControl name="lastname" label="Your Last Name" />
                  <FormControl name="email" label="Your Email" />
                  <FormControl
                    name="phone"
                    label="Your Phone Number"
                    type="number"
                  />
                  <FormControl
                    name="BVN"
                    label="Bank Verification Number (BVN)"
                    type="number"
                  />

                  <FormControl
                    name="gender"
                    label="Your Gender"
                    accepter={RadioGroup}
                    inline
                  >
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </FormControl>

                  <FormControl
                    name="education"
                    label="Level of Education"
                    cleanable={false}
                    searchable={false}
                    data={educationList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />

                  <FormControl
                    name="relationship_status"
                    label="Relationship Status"
                    cleanable={false}
                    searchable={false}
                    data={relationshipList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />

                  <FormControl
                    name="do_you_have_children"
                    label="Do You Have Children?"
                    accepter={RadioGroup}
                    inline
                    onChange={(value) => {
                      if (value === 'Yes') {
                        personalModel.spec['how_many'] =
                          Schema.Types.StringType().isRequired(
                            'This field is required.'
                          );
                      } else {
                        delete personalModel.spec['how_many'];
                        personalDetails.how_many = '';
                        personalDetails.do_you_have_children = 'No';
                        setPersonalDetails({ ...personalDetails });
                      }
                      personalFormRef.current.check();
                      setPersonalModel(personalModel);
                    }}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </FormControl>

                  <FormControl
                    name="how_many"
                    label="How Many Children?"
                    disabled={personalDetails?.do_you_have_children === 'No'}
                    cleanable={false}
                    searchable={false}
                    data={childrensList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />

                  <div className="page-footer">
                    <Button
                      appearance="primary"
                      size="lg"
                      type="submit"
                      onClick={() => {
                        savePersonalAndNext();
                      }}
                    >
                      Save and Next
                    </Button>
                  </div>
                </Form>
              </div>
            )}

            {step === 1 && (
              <div className="personal-info-form">
                <h4 className="form-title">Address Information</h4>
                <h4 className="form-info-title">
                  Always provide accurate details. Lenders will always verify
                  your details.
                </h4>

                <Form
                  layout="inline"
                  ref={addressFormRef}
                  model={addressModel}
                  formValue={addressDetails}
                  onChange={setAddressDetails}
                >
                  <div className="full-width">
                    <FormControl
                      name="street"
                      label="Your Current Street Address"
                    />
                  </div>
                  <FormControl
                    name="state"
                    label="Residence State"
                    cleanable={false}
                    searchable={false}
                    data={statesList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                    onChange={(value) => {
                      const locals = statesLocals[value].map((local) => {
                        return {
                          label_value: local,
                          actual_value: local,
                        };
                      });
                      setLocalAreaList(locals);
                      addressDetails.state = value;
                      addressDetails.local_government = '';
                      setAddressDetails({ ...addressDetails });
                    }}
                  />
                  <FormControl
                    name="local_government"
                    label="Local Government Area (LGA)"
                    cleanable={false}
                    searchable={false}
                    data={localAreaList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />
                  <div className="page-footer">
                    <Button
                      appearance="ghost"
                      onClick={() => {
                        setStep(0);
                      }}
                      size="lg"
                    >
                      Previous
                    </Button>
                    <Button
                      appearance="primary"
                      size="lg"
                      type="submit"
                      onClick={() => {
                        saveAddressAndNext();
                      }}
                    >
                      Save and Next
                    </Button>
                  </div>
                </Form>
              </div>
            )}

            {step === 2 && (
              <div className="personal-info-form">
                <h4 className="form-title">Employment Status</h4>
                <h4 className="form-info-title">
                  Always provide accurate details. Lenders will always verify
                  your details.
                </h4>

                <Form
                  layout="inline"
                  ref={employementFormRef}
                  model={employmentModel}
                  formValue={employmentDetails}
                  onChange={setEmploymentDetails}
                >
                  <FormControl
                    name="employer_name"
                    label="Employer Company Name"
                  />
                  <FormControl
                    name="employer_address"
                    label="Employment Office Address"
                  />

                  <FormControl
                    name="employment_status"
                    label="Employment Status"
                    cleanable={false}
                    searchable={false}
                    data={employmentStatusList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />
                  <FormControl
                    name="employment_start_date"
                    autoComplete="off"
                    format="dd/MM/yyyy"
                    label="Current Employment Start Date"
                    accepter={DatePicker}
                  />

                  <div className="rs-form-group salary-amount-input">
                    <span className="currency">NGN</span>
                    <FormControl
                      name="salary"
                      label="Monthly Salary Amount"
                      type="number"
                    />
                  </div>

                  <FormControl
                    name="frequency_of_salary"
                    label="Frequency of Salary Payment"
                    cleanable={false}
                    searchable={false}
                    data={salaryFrequencyList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />

                  <div className="page-footer">
                    <Button
                      appearance="ghost"
                      onClick={() => {
                        setStep(1);
                      }}
                      size="lg"
                    >
                      Previous
                    </Button>
                    <Button
                      appearance="primary"
                      size="lg"
                      type="submit"
                      onClick={() => {
                        saveEmploymentAndNext();
                      }}
                    >
                      Save and Next
                    </Button>
                  </div>
                </Form>
              </div>
            )}

            {step === 3 && (
              <div className="personal-info-form">
                <h4 className="form-title">Credit History</h4>
                <h4 className="form-info-title">
                  Always provide accurate details. Lenders will always verify
                  your details.
                </h4>

                <Form
                  layout="inline"
                  ref={creditHistoryFormRef}
                  model={creditHistoryModel}
                  formValue={creditHistoryDetails}
                  onChange={setCreditHistoryDetails}
                >
                  <FormControl
                    name="any_active_loan"
                    label="Do You Currently Have an Active Loan?"
                    accepter={RadioGroup}
                    inline
                    onChange={(value) => {
                      if (value === 'Yes') {
                        creditHistoryModel.spec['active_loan_lender_name'] =
                          Schema.Types.StringType().isRequired(
                            'This field is required.'
                          );
                        creditHistoryModel.spec['monthly_loan_repayment'] =
                          Schema.Types.StringType().isRequired(
                            'This field is required.'
                          );
                      } else {
                        delete creditHistoryModel.spec[
                          'active_loan_lender_name'
                        ];
                        delete creditHistoryModel.spec[
                          'monthly_loan_repayment'
                        ];
                        creditHistoryDetails.active_loan_lender_name = '';
                        creditHistoryDetails.monthly_loan_repayment = '';
                        creditHistoryDetails.any_active_loan = 'No';
                        setCreditHistoryDetails({ ...creditHistoryDetails });
                      }
                      creditHistoryFormRef.current.check();
                      setCreditHistoryModel(creditHistoryModel);
                    }}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </FormControl>

                  <Form.Group></Form.Group>

                  <FormControl
                    name="active_loan_lender_name"
                    label="Who Is the Lender?"
                    cleanable={false}
                    searchable={false}
                    data={lendersList}
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                    disabled={creditHistoryDetails.any_active_loan === 'No'}
                  />

                  <FormControl
                    name="monthly_loan_repayment"
                    label="How Much Is Your Monthly Loan Repayment?"
                    type="number"
                    disabled={creditHistoryDetails.any_active_loan === 'No'}
                  />

                  <FormControl
                    name="default_on_loan_payment"
                    label="Have You Ever Defaulted on a Loan Repayment?"
                    accepter={RadioGroup}
                    inline
                    onChange={(value) => {
                      if (value === 'Yes') {
                        creditHistoryModel.spec['default_years_ago'] =
                          Schema.Types.StringType().isRequired(
                            'This field is required.'
                          );
                      } else {
                        delete creditHistoryModel.spec['default_years_ago'];
                        creditHistoryDetails.default_years_ago = '';
                        creditHistoryDetails.default_on_loan_payment = 'No';
                        setCreditHistoryDetails({ ...creditHistoryDetails });
                      }
                      creditHistoryFormRef.current.check();
                      setCreditHistoryModel(creditHistoryModel);
                    }}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </FormControl>

                  <FormControl
                    name="default_years_ago"
                    label="When Did You Default on a Loan?"
                    cleanable={false}
                    searchable={false}
                    data={defaultYearsList}
                    disabled={
                      creditHistoryDetails.default_on_loan_payment === 'No'
                    }
                    labelKey="actual_value"
                    valueKey="actual_value"
                    accepter={SelectPicker}
                  />

                  <div className="page-footer">
                    <Button
                      appearance="ghost"
                      onClick={() => {
                        setStep(2);
                      }}
                      size="lg"
                    >
                      Previous
                    </Button>
                    <Button
                      appearance="primary"
                      size="lg"
                      type="submit"
                      onClick={() => {
                        saveCreditHistoryAndNext();
                      }}
                    >
                      Save and Finish
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Application;
