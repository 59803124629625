import { Navbar, Nav } from "rsuite";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../.././utils/AuthContext";
import CogIcon from "@rsuite/icons/legacy/Cog";
import logo from "../../assets/logo.webp";

const Navigation = () => {
  const { user, logoutUser } = useAuth();
  const navigate = useNavigate();

  const handleNavItem = (route) => {
    navigate(route);
  };

  return (
    <>
      <Navbar appearance="default" className="app-nav-bar">
        {user ? (
          <NavLink to="/dashboard">
            <Navbar.Brand>
              <img width="180" src={logo} alt="Logo" />
            </Navbar.Brand>
          </NavLink>
        ) : (
          <NavLink to="/application">
            <Navbar.Brand>
              <img width="180" src={logo} alt="Logo" />
            </Navbar.Brand>
          </NavLink>
        )}

        <Nav pullRight>
          {!user ? (
            <>
              <Nav.Item onSelect={() => handleNavItem("/application")}>
                Application
              </Nav.Item>
              <Nav>
                <Nav.Item onSelect={() => handleNavItem("/login")}>
                  Login
                </Nav.Item>
              </Nav>
            </>
          ) : (
            <Nav.Item onClick={() => logoutUser()} icon={<CogIcon />}>
              Logout
            </Nav.Item>
          )}
        </Nav>
      </Navbar>
    </>
  );
};

export default Navigation;
