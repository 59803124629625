import { Routes, Route } from "react-router-dom";

import { useAuth } from "./utils/AuthContext";

import Login from "./components/pages/Login";
import Application from "./components/Application/Application";
import Navigation from "./components/Navigation/Navigation";
import Dashboard from "./components/Dashboard/Dashboard";

import PrivateRoutes from "./utils/PrivateRoutes";

import "rsuite/dist/rsuite-no-reset.min.css";
import "./App.scss";

export default function App() {
  const { user } = useAuth();
  return (
    <>
      <Navigation />
      <Routes>
        {!user && (
          <>
            <Route path="/application" element={<Application />} />
            <Route path="/login" element={<Login />} />
          </>
        )}
        <Route element={<Dashboard />} path="/dashboard" exact />

        <Route element={<PrivateRoutes />}>
          <Route element={<Dashboard />} path="/dashboard" exact />
        </Route>

        {!user ? (
          <Route path="*" element={<Application />} />
        ) : (
          <Route path="*" element={<Dashboard />} />
        )}
      </Routes>
    </>
  );
}
