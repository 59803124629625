import { NavLink } from 'react-router-dom';

import { Message } from 'rsuite';

const ActiveApplication = () => {
  return (
    <div className="creditnigeria-app">
      <div className="container">
        <div className="page-body">
          <Message showIcon type="warning" header="Warning">
            You have already an application, please Login{' '}
            <NavLink to="/login">here</NavLink> to see all the details.
          </Message>
        </div>
      </div>
    </div>
  );
};

export default ActiveApplication;
