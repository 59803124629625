import { Message } from "rsuite";

import BorrowersDashboard from "../../Borrowers/BorrowersDashboard";
import "./Offers.scss";

const Offers = (props) => {
  const { borrowerEmail, lenders, dealID } = props;
  return (
    <div className="offers-page">
      <div className="container offers-page-container">
        <div className="page-body offers-page-body">
          <div
            style={{
              marginBottom: "24px",
              padding: "0 24px",
              fontWeight: "bold",
            }}
          >
            <Message type="success">
              Login details sent to provided email.
            </Message>
          </div>

          <BorrowersDashboard
            isBeforeSignup
            borrowerEmail={borrowerEmail}
            lenders={lenders}
            dealID={dealID}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
