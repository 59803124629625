import { createContext, useState, useEffect, useContext } from 'react';

import Loading from '../shared/Loading/Loading';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.catalyst.auth
      .isUserAuthenticated()
      .then((result) => {
        if (result.status === 200) {
          setUser(result.content);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const logoutUser = async () => {
    setLoading(true);
    setUser(null);
    window.catalyst.auth.signOut('/');
  };

  const contextData = {
    user,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? <Loading message="Loading..." /> : children}
    </AuthContext.Provider>
  );
};

//Custom Hook
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
